@import "../../assets/styles/variables";

.Tooltip {
  display: flex !important;
  flex-direction: column;
  border-radius: 8px !important;
  padding: 18px !important;
  background-color: #fff !important;
  color: $text-dark-blue !important;
  box-shadow: 0 12px 12px 2px rgba(0, 0, 0, 0.05);
  transition: opacity .3s ease, visibility .3s ease !important;

  &:global {
    &.show {
      opacity: 1 !important;
    }

    &.place-right {
      &::after {
        border-right-color: #fff !important;
        border-top: 10px solid transparent !important;
        border-bottom: 10px solid transparent !important;
        left: -14px !important;
        margin-top: -10px !important;
        border-right-width: 14px !important;
      }
    }

    &.place-top {
      &::after {
        border-top-color: #fff !important;
        border-left: 10px solid transparent !important;
        border-right: 10px solid transparent !important;
        bottom: -14px !important;
        left: 50% !important;
        margin-left: -10px !important;
        border-top-width: 14px !important;
      }
    }
  }
}
