@import "../../assets/styles/variables";

.Auth {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  padding: 40px 15px;
}

:global(.pro) {
  .Auth {
    padding: 0;
  }
}
