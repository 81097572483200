@import "../../assets/styles/variables";

.ProLayout {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  background: url('../../../public/bg-pro.png') no-repeat left top;
}

.ProLayout__Container {
  max-width: 1190px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-grow: 1;
  margin: 0 auto;
}

.ProLayout__Logo {
  display: flex;
  align-items: center;
  position: absolute;
  top: 64px;
  left: 60px;
  transition-property: transform, top, left;
  transition-duration: .3s;
  transition-timing-function: ease;
}

.ProLayout__Help {
  flex-shrink: 0;
  margin-top: 40px;
  margin-bottom: 40px;
  font-weight: 600;
  font-size: 14px;
  line-height: 30px;
  text-align: center;
  color: $text-dark-blue;

  a {
    color: $blue;
    transition: color .3s ease;

    &:hover,
    &:focus {
      color: $text-blue;
    }
  }
}

@media screen and (max-width: $tablet) {
  .ProLayout {
    background: url('../../../public/bg-pro.png') no-repeat left -240px top;

    &__Container {
      max-width: 920px;
    }
  }
}

@media screen and (max-width: $tablet-small) {
  .ProLayout {
    background: none;

    &__Container {
      max-width: 500px;
      align-items: flex-start;
      padding-top: 80px;
    }

    &__Logo {
      transform: translateX(-50%);
      left: 50%;
      top: 30px;

      img {
        max-width: 155px;
      }
    }

    &__Help {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
}
