@import "../../assets/styles/variables";

.Header {
  width: 100%;
  max-width: 1300px;
  flex-shrink: 0;
  padding: 42px 0 34px 0;
  margin: 0 auto;
}

.Header__Container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Header__Nav {
  ul {
    display: flex;
    align-items: center;

    li {
      display: flex;
      margin-right: 50px;

      a {
        font-weight: 600;
        color: #D1D9FF;
        position: relative;
        text-decoration: none;
        transition: color .3s ease;

        &:after {
          position: absolute;
          display: block;
          content: '';
          background: #D1D9FF;
          opacity: 0.1;
          width: 100%;
          height: 2px;
          left: 0;
          transition: background 0.3s ease, opacity 0.3s ease;
          bottom: -5px;
        }
      }

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        a {
          color: #fff;

          &:after{
            background: #488FF0;
            opacity: 1;
          }
        }
      }
    }
  }
}

.Header__Logo {
  display: flex;
  margin-right: auto;
  margin-left: 154px;

  img {
    width: 150px;
    height: 26.5px;
  }
}

.Header__Right {
  display: flex;
  align-items: center;
}

.Header__Login {
  display: flex;
  align-items: center;
  margin-right: 25px;
  cursor: pointer;

  svg {
    margin-right: 10px;
    fill: #D1D9FF;
    transition: fill .3s ease;
  }

  span {
    font-size: 14px;
    line-height: 16px;
    font-weight: bold;
    color: #D1D9FF;
    transition: color .3s ease;
  }

  &:hover {
    svg {
      fill: #fff;
    }

    span{
      color: #fff;
    }
  }
}

.Header__MenuBtn {
  display: none;
  cursor: pointer;
}

@media screen and (max-width: $tablet) {
  .Header {
    max-width: 768px;
    padding: 0 44px;
    margin-top: 26px;

    &__MenuBtn {
      display: flex;
    }

    &__Nav {
      display: none;
    }

    &__Right {
      & > a {
        display: none;
      }
    }

    &__Logo {
      margin-left: 232px;
    }
  }
}

@media screen and (max-width: $mobile) {
  .Header {
    max-width: 100%;
    padding: 0 14px;
    margin-top: 20px;

    &__MenuBtn {
      img {
        width: 24px;
      }
    }

    &__Logo {
      margin: 0;
    }
  }
}


