@import "../../assets/styles/variables";

.Footer {
  width: 100%;
  flex-shrink: 0;
  position: relative;
  padding: 30px 0;
  background: #152355;
}

.Footer__Container {
  max-width: 1350px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
}

.Footer__Logo {
  display: flex;

  img {
    width: 120px;
    height: auto;
  }
}

.Footer__Nav {
  display: flex;
  align-items: center;

  li {
    display: flex;
    margin-right: 48px;

    a {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #D9DAFF;

      &:hover {
        color: #fff;
      }
    }

    &:last-child {
      margin: 0;
    }
  }
}

.Footer__Created {
  display: flex;
  align-items: center;

  span {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #7B7FB7;

    &:last-child {
      font-weight: bold;
    }
  }

  img{
    margin: 0 5px;
  }
}

@media screen and (max-width: $tablet) {
  .Footer {
    &__Container {
      flex-direction: column;
    }

    &__Nav {
      margin: 30px 0;

      li {
        margin-right: 40px;
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  .Footer {
    padding: 26px 0;

    &__Nav {
      width: 100%;
      flex-direction: column;

      li {
        margin-right: 0;
        margin-bottom: 25px;
      }
    }
  }
}
