@import "../../assets/styles/variables";

.Error {
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;

  img {
    width: 100%;
  }
}

.Error__Illustration {
  max-width: 100%;
  display: block;
  margin-bottom: 48px;
}

.Error__Title {
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: $text-dark-blue;
  margin: 32px 0;
}

.Error__Buttons {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 68px;

  a, button {
    width: 100%;

    &:first-child {
      margin-bottom: 32px;
    }
  }
}

@media only screen and (max-width: $mobile) {
  .Error {
    padding: 30px 0;

    &__Title {
      margin-bottom: 24px;
    }

    &__Buttons {
      padding: 0 24px;
    }
  }
}
