@import "variables";

html, body {
  min-height: 100vh;
}

body {
  display: flex;
  flex-direction: column;
  margin: 0;
  font-weight: 400;
  font-size: 14px;
}

body.default {
  background: url('../../../public/bg.png') no-repeat center center / cover;
  font-family: Raleway, Arial, Tahoma, sans-serif;
}

body.pro {
  background-image: none;
  background-color: $light-grey;
  font-family: Arial, Tahoma, sans-serif;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

input {
  outline: none;
}

button {
  padding: 0;
  outline: none;
  margin: 0;
  border: none;
  box-shadow: none;
}

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.form-block {
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
}

.form-agree {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  font-family: Open Sans, sans-serif;

  input {
    display: none;

    &:checked + label::before {
      content: '';
      border: 1px solid $blue;
      background-color: $blue;
      background-size: 70%;
      background-image: url(https://static.surfe.be/tpl/land-adv/img/check-line.svg);
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  label {
    display: flex;
    align-items: center;
    margin-right: 12px;
    background-color: #fff;

    &::before {
      content: '';
      width: 18px;
      height: 18px;
      border-radius: 4px;
      border: 1px solid $light-grey;
      cursor: pointer;
      transition: box-shadow .3s ease;
    }
  }

  span {
    font-size: 12px;
    line-height: 14px;
    color: $dark-grey;
    margin-right: 5px;
  }

  a {
    font-size: 12px;
    line-height: 14px;
    color: $text-blue;

    &:hover {
      color: $blue;
    }
  }

  &_isInvalid {
    label {
      &::before {
        box-shadow: 0 0 0 2px $red;
      }
    }
  }
}

.forgot-password {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-bottom: 16px;

  a {
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: $text-light-grey;
    transition: color .3s ease;

    &:hover{
      color: $text-blue;
    }
  }
}

body.pro {
  .forgot-password {
    display: inline-block;
    margin-bottom: 24px;
    cursor: pointer;
    transition: color .3s ease;

    a {
      font-size: 14px;
      line-height: 19px;
      color: $text-dark-grey;

      &:hover{
        color: $text-blue;
      }
    }
  }

  .form-block {
    margin-bottom: 18px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .form-agree {
    margin-bottom: 18px;
  }
}
