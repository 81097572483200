@import "../../assets/styles/variables";

.TipMessage {
  font-family: Open Sans, sans-serif;
  font-size: 12px;
  text-align: center;
  line-height: 16px;
  padding-top: 8px;
  color: $dark-grey;
}

.TipMessage__Red {
  color: $red;
  font-weight: 600;
}

.TipMessage__Green {
  color: $green;
  font-weight: 600;
}
