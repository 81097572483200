@import "../../assets/styles/variables";

.MobileMenu {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(26, 31, 50, 0);
  pointer-events: none;
  z-index: 10;
  transition: background-color .3s ease;
}

.MobileMenu__Content {
  width: 477px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #152355;
  background-image: url(https://static.surfe.be/tpl/land-adv/img/nav-background.svg);
  background-repeat: no-repeat;
  background-position: center;
  z-index: 10000;
  transition: transform .3s ease;
  transform: translateX(-100%);
}

.MobileMenu__Close {
  display: flex;
  cursor: pointer;
}

.MobileMenu__Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  padding-left: 46px;
  padding-right: 16px;
  padding-top: 28px;
  margin-bottom: 42px;
}

.MobileMenu__Nav {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  padding: 0 44px;
}

.MobileMenu__NavTop {
  display: flex;
  flex-direction: column;

  li {
    display: flex;
    margin-bottom: 36px;

    a {
      position: relative;
      font-weight: bold;
      font-size: 24px;
      line-height: 28px;
      color: #D1D9FF;

      &:after{
        width: 100%;
        height: 2px;
        display: block;
        content: '';
        position: absolute;
        left: 0;
        bottom: -5px;
        background-color: #D1D9FF;
        opacity: 0.1;
        transition: opacity .3s ease, background-color .3s ease;
      }
    }

    &:last-child {
      margin: 0;
    }

    &:hover {
      a {
        color: #fff;

        &:after {
          background: #488FF0;
          opacity: 1;
        }
      }
    }
  }
}

.MobileMenu__NavBottom {
  display: flex;
  flex-wrap: wrap;

  li {
    height: 40px;
    display: flex;
    margin-right: 32px;

    a {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #D9DAFF;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.MobileMenu_isOpen {
  background-color: rgba(26, 31, 50, 0.7);
  pointer-events: auto;

  .MobileMenu {
    &__Content {
      transform: translateX(0);
    }
  }
}

@media screen and (max-width: $tablet) {
  .MobileMenu {
    display: flex;
  }
}

@media screen and (max-width: $mobile) {
  .MobileMenu {
    display: flex;

    &__Header {
      padding-left: 16px;
      padding-right: 0;
      padding-top: 20px;
      margin-bottom: 10px;
    }

    &__Close {
      img {
        width: 18px;
      }
    }

    &__Nav {
      padding: 0 16px;
    }

    &__NavTop {
      li {
        margin-bottom: 30px;

        a {
          font-weight: bold;
          font-size: 18px;
          line-height: 22px;
        }
      }
    }

    &__NavBottom {
      li {
        margin-right: 24px;
      }
    }
  }
}
