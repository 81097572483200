@import "../../assets/styles/variables";

.Form {
  width: 100%;
  max-width: 400px;
  border-radius: 8px;
  padding: 24px 32px;
  margin: 0 auto;
  background-color: #fff;
  overflow: hidden;
}

.Form__Title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 32px;
  color: $text-dark-blue;
}

.Form__Arr {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  margin-right: 20px;

  svg {
    fill: #BABACB;
    transition: fill .3s ease;
  }

  &:hover {
    svg {
      fill: $text-blue;
    }
  }
}

.Form__Social {
  display: flex;
  flex-direction: column;
  margin-top: 24px;

  ul {
    display: flex;
    justify-content: center;

    li {
      margin-right: 12px;

      a {
        display: flex;
      }

      &:last-child {
        margin: 0;
      }
    }
  }
}

.Form__SocialTitle {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: $text-light-grey;
  margin-bottom: 16px;
  text-align: center;
}

.Form__Nav {
  margin-bottom: 32px;

  a {
    font-family: 'Raleway', sans-serif;
    font-weight: 800;
    font-size: 24px;
    line-height: 28px;
    color: $text-light-grey;
    transition: color .3s ease;

    &:first-child {
      margin-right: 24px;
    }

    &.isActive {
      color: $text-dark-blue;
    }

    &:hover {
      color: $text-blue;
    }
  }
}

.Form__Image {
  height: 220px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 30px;
}

.Form__Text {
  font-family: 'Open Sans', Arial, Tahoma, sans-serif;
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
}

.Form__TextWarn {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 18px;
  margin-top: 18px;
  background: #FFF1E2;
  border-radius: 6px;

  p {
    margin: 0 0 0 15px;
    font-size: 12px;
    line-height: 20px;
    font-weight: 600;
    color: #ce7010;
  }
}

.Form__Link {
  text-align: center;
  margin-bottom: 16px;

  span, a {
    color: $blue-deep;
    font-family: 'Open Sans', Arial, Tahoma, sans-serif;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    transition: color .3s ease;

    &:hover,
    &:focus {
      color: $text-blue;
    }
  }
}

.Form__CodeDescription {
  margin-top: 32px;
  border: 1px solid $grey;
  border-radius: 8px;
  padding: 24px 45px 35px 45px;
  margin-bottom: 16px;
  font-size: 12px;
  font-weight: 600;

  a {
    color: $blue-deep;
    transition: color .3s ease;

    &:hover,
    &:focus {
      color: $text-blue;
    }
  }
}

.Form__CodeImage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto 24px;

  img {
    width: 100px;
  }

  span {
    line-height: 14px;
    text-align: center;
  }
}

.Form__CodeStep {
  display: flex;
  align-items: flex-start;

  &:last-child {
    margin-top: 24px;
  }

  & > span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    min-width: 32px;
    border-radius: 50%;
    border: 1px solid #e6eaef;
    font-weight: 600;
    font-size: 12px;
    line-height: 30px;
    text-align: center;
    margin-right: 12px;
  }

  & > p {
    margin: 0;
    line-height: 18px;
  }
}

.Form__MailBottom {
  font-weight: 600;
  font-family: 'Open Sans', Arial, Tahoma, sans-serif;
}

.Form__MailTimer {
  text-align: center;
  margin-top: 16px;

  a {
    font-family: 'Open Sans', Arial, Tahoma, sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: $blue-deep;
    cursor: pointer;
    -webkit-appearance: none;
    transition: color .3s ease;

    &:hover,
    &:focus {
      color: $text-blue;
    }
  }
}

.Form__Text_mail {
  p {
    svg {
      margin-right: 6px;
      margin-bottom: -2px;
    }
  }
}

.Form_2fa {
  max-width: 650px;
  padding: 0 0 30px;

  .Form__Text,
  .Form__Title,
  form {
    padding: 0 115px;
  }
}

:global(.default) {
  .Form {
    &__Image {
      display: none;
    }

    &_2fa {
      position: relative;
      padding-top: 185px;
      border-radius: 18px;
      background-image: url('../../../public/header_bg.png');
      background-repeat: no-repeat;
      background-size: 100% auto;
      background-position: center top;

      &::before {
        width: 100%;
        height: 164px;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        background-image: url('../../../public/profile-edit.png');
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position: center top;
      }

      .Form {
        &__Image {
          display: none;
        }
      }
    }
  }
}

:global(.pro) {
  .Form {
    max-width: 460px;
    padding: 48px 0 0;
    border: 1px solid $grey;
    margin-left: auto;
    margin-right: 0;

    &__Nav,
    &__Title,
    form {
      padding: 0 48px;
    }

    &__Social {
      background-color: $light-grey;
      border-top: 1px solid $grey;
      padding: 24px 48px;

      ul {
        li {
          display: flex;
          margin-right: 14px;
          flex-grow: 1;

          a {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #DEE3EC;
            padding: 14px;
            border-radius: 6px;
            transition: background-color .3s ease, border-color .3s ease;

            &:hover{
              background-color: #F1F1F1;
              border-color: #F1F1F1;
            }
          }

          &:last-child {
            margin: 0;
          }
        }
      }
    }

    &__Title {
      justify-content: flex-start;
      font-family: Raleway, sans-serif;
      font-weight: 800;
      font-size: 24px;
      line-height: 28px;
    }

    &__Text {
      padding: 0 48px;
      margin-bottom: 38px;
      font-family: 'Open Sans', sans-serif;
      font-size: 16px;
      line-height: 28px;
      font-weight: normal;

      &_mail {
        p {
          svg {
            width: 16px;
            height: 16px;
          }
        }
      }
    }

    &__TextWarn {
      p {
        font-size: 14px;
      }
    }

    &_2fa {
      max-width: 580px;
      padding-top: 0;
      margin-right: -80px;

      .Form {
        &__Title {
          margin-bottom: 18px;
        }
      }
    }
  }
}

@media screen and (max-width: $tablet) {
  :global(.pro) {
    .Form {
      &_2fa {
        max-width: 460px;
      }
    }
  }
}

@media screen and (max-width: $tablet-small) {
  :global(.pro) {
    .Form {
      max-width: 100%;
      margin: 0 auto;
      padding-top: 32px;
      border-radius: 0;

      &__Nav,
      &__Title,
      &__Text,
      form {
        padding: 0 24px;
      }

      &__Nav {
        text-align: center;
      }

      &__Social {
        padding: 24px;
      }

      &__Text {
        margin-bottom: 18px;
      }

      &_2fa {
        margin-right: 0;
        padding-top: 0;
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  .Form {
    padding: 24px;

    &__CodeDescription {
      padding: 24px;
    }

    &_2fa {
      max-width: 500px;
      padding: 0 0 30px;

      .Form__Text,
      .Form__Title,
      form {
        padding: 0 14px;
      }
    }
  }

  :global(.default) {
    .Form {
      &_2fa {
        padding-top: 160px;
        background-size: auto 140px;

        &::before {
          height: 140px;
          background-size: auto 140px;
        }
      }
    }
  }
}
