@import "../../assets/styles/variables";

.Captcha {
  width: 100%;
  margin-bottom: 16px;

  div {
    width: 100% !important;
  }

  iframe {
    display: block;
    margin: 0 auto;
  }
}

.Captcha__Message {
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: $red;
  margin-top: 16px;
}
