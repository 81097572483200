@import "../../assets/styles/variables";

.Popup {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  background: rgba(26, 31, 50, 0.7);
  backdrop-filter: blur(10px);
  overflow-y: scroll;
  z-index: 1200;
  transition: opacity .3s ease;

  &__Content {
    max-width: 740px;
    position: absolute;
    left: 50%;
    margin: 5rem 0 5rem;
    transform: translate(-50%);
    background: #fff;
    border-radius: 18px;
  }

  &__Close {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    background: #FFFFFF;
    border-radius: 50%;
    cursor: pointer;
    transform: translate(50%, -50%);
    z-index: 1201;
  }
}

.PopupMessage {
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px 45px;
  text-align: center;
  color: #3D3D3D;

  button,
  a {
    max-width: 300px;
  }

  button:not(:last-child) {
    margin-bottom: 16px;
  }
}

.PopupMessage__Icon {
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 12px auto;

  img {
    width: 100%;
  }
}

.PopupMessage__Title {
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 16px;
}

.PopupMessage__Text {
  font-size: 14px;
  margin-bottom: 32px;
}

.PopupImage {
  width: 550px;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 20px 38px 24px;
  overflow: hidden;
}

.PopupImage__Title {
  margin-bottom: 28px;
  font-size: 16px;
  text-align: center;
  color: #3D3D3D;
  align-items: center;
}

.PopupImage__Wrap {
  display: flex;
  align-items: center;
  justify-content: center;
}

.PopupImage__Content {
  width: 226px;
  background-color: #fff;
  border: 1px solid #e6eaef;
  border-radius: 6px;
  overflow: hidden;
  margin: 0 6px;
}

.PopupImage__Main {
  padding: 24px 12px 32px 12px;

  img {
    display: block;
    margin: 0 auto 18px auto;
  }
}

.PopupImage__Info {
  background-color: #FAFBFD;
  border-top: 1px solid #e6eaef;
  padding: 14px 8px;
  font-size: 12px;
  text-align: center;
  color: #424954;
  font-style: italic;
  font-weight: 400;
}

.PopupImage__Loader {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border-radius: 18px;
  background: #fff;
  opacity: 0;
  pointer-events: none;
  transition: opacity .3s ease;

  img {
    width: 150px;
  }
}

.PopupImage__Crop {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;

  img {
    width: 100%;
  }

  :global {
    .ReactCrop {
      position: relative;
      display: inline-block;
      cursor: crosshair;
      overflow: hidden;
      max-width: 100%;

      &__crop-selection {
        position: absolute;
        top: 0;
        left: 0;
        transform: translate3d(0, 0, 0);
        box-sizing: border-box;
        cursor: move;
        box-shadow: 0 0 0 9999em rgb(0 0 0 / 50%);
        touch-action: none;
        border: 1px solid;
        border-image-source: url(data:image/gif;base64,R0lGODlhCgAKAJECAAAAAP///////wAAACH/C05FVFNDQVBFMi4wAwEAAAAh/wtYTVAgRGF0YVhNUDw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OEI5RDc5MTFDNkE2MTFFM0JCMDZEODI2QTI4MzJBOTIiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OEI5RDc5MTBDNkE2MTFFM0JCMDZEODI2QTI4MzJBOTIiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoTWFjaW50b3NoKSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuZGlkOjAyODAxMTc0MDcyMDY4MTE4MDgzQzNDMjA5MzREQ0ZDIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjAyODAxMTc0MDcyMDY4MTE4MDgzQzNDMjA5MzREQ0ZDIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+Af/+/fz7+vn49/b19PPy8fDv7u3s6+rp6Ofm5eTj4uHg397d3Nva2djX1tXU09LR0M/OzczLysnIx8bFxMPCwcC/vr28u7q5uLe2tbSzsrGwr66trKuqqainpqWko6KhoJ+enZybmpmYl5aVlJOSkZCPjo2Mi4qJiIeGhYSDgoGAf359fHt6eXh3dnV0c3JxcG9ubWxramloZ2ZlZGNiYWBfXl1cW1pZWFdWVVRTUlFQT05NTEtKSUhHRkVEQ0JBQD8+PTw7Ojk4NzY1NDMyMTAvLi0sKyopKCcmJSQjIiEgHx4dHBsaGRgXFhUUExIREA8ODQwLCgkIBwYFBAMCAQAAIfkEBQoAAgAsAAAAAAoACgAAAhWEERkn7W3ei7KlagMWF/dKgYeyGAUAIfkEBQoAAgAsAAAAAAoACgAAAg+UYwLJ7RnQm7QmsCyVKhUAIfkEBQoAAgAsAAAAAAoACgAAAhCUYgLJHdiinNSAVfOEKoUCACH5BAUKAAIALAAAAAAKAAoAAAIRVISAdusPo3RAzYtjaMIaUQAAIfkEBQoAAgAsAAAAAAoACgAAAg+MDiem7Q8bSLFaG5il6xQAIfkEBQoAAgAsAAAAAAoACgAAAg+UYRLJ7QnQm7SmsCyVKhUAIfkEBQoAAgAsAAAAAAoACgAAAhCUYBLJDdiinNSEVfOEKoECACH5BAUKAAIALAAAAAAKAAoAAAIRFISBdusPo3RBzYsjaMIaUQAAOw==);
        border-image-slice: 1;
        border-image-repeat: repeat;
      }

      &__drag-handle {
        position: absolute;

        &::after {
          position: absolute;
          content: "";
          display: block;
          width: 10px;
          height: 10px;
          background-color: rgba(0,0,0,.2);
          border: 1px solid rgba(255,255,255,.7);
          box-sizing: border-box;
          outline: 1px solid transparent;
        }
      }

      .ord-nw {
        top: 0;
        left: 0;
        margin-top: -5px;
        margin-left: -5px;
        cursor: nw-resize;

        &::after {
          top: 0;
          left: 0;
        }
      }

      .ord-n {
        top: 0;
        left: 50%;
        margin-top: -5px;
        margin-left: -5px;
        cursor: n-resize;

        &::after {
          top: 0;
        }
      }

      .ord-ne {
        top: 0;
        right: 0;
        margin-top: -5px;
        margin-right: -5px;
        cursor: ne-resize;

        &::after {
          top: 0;
          right: 0;
        }
      }

      .ord-e {
        top: 50%;
        right: 0;
        margin-top: -5px;
        margin-right: -5px;
        cursor: e-resize;

        &::after {
          right: 0;
        }
      }

      .ord-se {
        bottom: 0;
        right: 0;
        margin-bottom: -5px;
        margin-right: -5px;
        cursor: se-resize;

        &::after {
          bottom: 0;
          right: 0;
        }
      }

      .ord-s {
        bottom: 0;
        left: 50%;
        margin-bottom: -5px;
        margin-left: -5px;
        cursor: s-resize;

        &::after {
          bottom: 0;
        }
      }

      .ord-sw {
        bottom: 0;
        left: 0;
        margin-bottom: -5px;
        margin-left: -5px;
        cursor: sw-resize;

        &::after {
          bottom: 0;
          left: 0;
        }
      }

      .ord-w {
        top: 50%;
        left: 0;
        margin-top: -5px;
        margin-left: -5px;
        cursor: w-resize;

        &::after {
          left: 0;
        }
      }

      &--fixed-aspect {
        .ReactCrop {
          &__drag-handle.ord-n,
          &__drag-handle.ord-e,
          &__drag-handle.ord-s,
          &__drag-handle.ord-w {
            display: none;
          }
        }
      }
    }
  }
}

.PopupImage_isLoading {
  .PopupImage__Loader {
    opacity: 1;
  }
}

.PopupMessage__Buttons {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  button,
  a {
    min-width: 0;
    margin-bottom: 16px;
  }

  button:last-child,
  a:last-child {
    margin-bottom: 0;
  }
}

.Popup {
  &:global {
    &.fade-enter-done {
      opacity: 1;
    }

    &.fade-exit {
      opacity: 1;
    }

    &.fade-exit-active {
      opacity: 0;
    }
  }
}

@media (max-width: $mobile) {
  .Popup {
    &__Content {
      width: 100%;
      max-width: 100%;
      left: 0;
      transform: none;
      margin: 0;
      border-radius: 0;
    }

    &__Close {
      top: 10px;
      right: 10px;
      transform: none;
    }
  }

  .PopupImage {
    width: 100%;
    padding: 10px;
  }

  .PopupMessage {
    width: 100%;
  }
}

:global(.dark-theme) {
  .Popup {
    &__Content {
      background-color: #1B1B22;
      color: #E3E3E3;
    }

    &__Close {
      background-color: #16161A;
    }
  }

  .PopupMessage {
    color: #E3E3E3;
  }

  .PopupImage {
    &__Content,
    &__Info {
      background-color: #16161A;
      border: none;
    }

    &__Info {
      color: #737E90;
    }
  }
}
