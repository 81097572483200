@import "../../assets/styles/variables";

.Lang {
  margin-right: 18px;
  position: relative;
}

.Lang__Current {
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px 8px 0 0;
  cursor: pointer;
  padding: 0 14px;
  transition: background-color .3s ease;

  img {
    margin-right: 10px;
  }

  span {
    font-weight: bold;
    font-size: 13px;
    line-height: 15px;
    color: #D1D9FF;
    margin-right: 5px;
    overflow: hidden;
    transition: color .3s ease;
  }

  svg {
    fill: #D1D9FF;
    transition: fill .3s ease;
  }

  &:hover {
    span {
      color: #fff;
    }

    svg {
      fill: #fff;
    }
  }
}

.Lang__Dropdown {
  width: 100%;
  display: flex;
  align-items: center;
  background: #fff;
  border-radius: 0 0 8px 8px;
  padding: 2px 14px 18px 14px;

  img {
    margin-right: 10px;
  }

  span {
    font-weight: bold;
    font-size: 13px;
    line-height: 15px;
    color: #2D2D2D;
  }
}

.Lang__DropdownWrap {
  width: 100%;
  height: 0;
  position: absolute;
  left: 0;
  top: 45px;
  cursor: pointer;
  z-index: 100;
  overflow: hidden;
  transition: height .3s ease;
}

.Lang_isOpen {
  .Lang {
    &__Current {
      background: #fff;

      span {
        color: #488FF0;
      }

      svg {
        fill: #488FF0;
        transform: rotate(-180deg);
      }
    }

    &__DropdownWrap {
      height: 35px;
    }
  }
}

.LangPro {
  min-width: 64px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  box-shadow: 0 8px 12px rgb(202 211 230 / 15%);
  border-radius: 64px;
  background-color: #fff;
  cursor: pointer;
  transition: background-color .3s ease;

  &:hover,
  &:focus {
    background-color: rgba(234, 237, 242, 0.6);
  }
}

@media screen and (max-width: $tablet) {
  .Lang {
    margin-right: 0;
  }

  .LangPro {
    min-width: 50px;
    height: 36px;
  }
}

@media screen and (max-width: $mobile) {
  .Lang {
    &__Current span,
    &__Dropdown span {
      display: none;
    }

    &_isOpen {
      .Lang {
        &__Current span,
        &__Dropdown span {
          display: inline-block;
        }
      }
    }
  }
}
