@import "../../assets/styles/variables";

.CodeInput {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 32px;

  &__Inputs {
    width: 100%;
    display: flex !important;
    flex-wrap: nowrap;
    justify-content: space-between;

    input {
      width: 60px;
      height: 85px;
      margin-right: 24px;
      background: $light-blue;
      border: 2px solid rgba(105, 108, 255, 0.2);
      border-radius: 8px;
      font-weight: 700;
      font-size: 36px;
      color: $text-dark-blue;
      text-align: center;

      &:last-of-type {
        margin-right: 0;
      }
    }

    input[type='number'] {
      -moz-appearance: textfield;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }

  &__Error {
    display: block;
    position: absolute;
    left: 0;
    bottom: -20px;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: $red;
  }
}

:global(.default) {
  .CodeInput {
    &__Inputs {
      input {
        width: 60px;
        height: 80px;
        background-color: #fff;
        border: 1px solid $grey;
        border-radius: 8px;
        margin-right: 0;
      }
    }
  }
}

:global(.pro) {
  .CodeInput {
    margin-bottom: 66px;
  }
}

@media screen and (max-width: $tablet) {
  .CodeInput {
    input {
      width: 50px;
      height: 70px;
      margin-right: 10px;
    }
  }
}

@media screen and (max-width: $tablet-small) {
  :global(.pro) {
    .CodeInput {
      margin-bottom: 32px;

      input {
        width: 14%;
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  :global(.default) {
    .CodeInput {
      &__Inputs {
        input {
          width: 14%;
          height: 70px;
        }
      }
    }
  }
}
