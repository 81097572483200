// Media queries
$mobile: '767px';
$tablet-small: '1023px';
$tablet: '1349px';

// Colors
$grey: #ECECF3;
$light-grey: #F5F8FC;
$dark-grey: #596C89;
$text-dark-grey: #6E7191;
$text-grey: #737E90;
$text-light-grey: #9BB0CA;
$light-blue: #f7f7ff;
$blue: #3B83E5;
$blue-deep: #007bff;
$text-blue: #488FF0;
$dark-blue: #46488F;
$text-dark-blue: #2A2B54;
$red: #E14B6D;
$pink-light: #FFF3F6;
$green: #5FCD53;
$yellow: #ffa500;
