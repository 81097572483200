@import "../../assets/styles/variables";

.Input {
  position: relative;

  input {
    width: 100%;
    background: #fff;
    border: 1px solid $light-grey;
    border-radius: 6px;
    font-family: Open Sans, sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    color: $text-grey;
    padding: 12px 12px 12px 50px;
    transition-property: border-color, color, margin-bottom;
    transition-duration: .3s;
    transition-timing-function: ease;

    &::placeholder {
      color: $text-light-grey;
      transition: color .3s ease;
    }

    &:focus::placeholder {
      color: rgba(155, 176, 202, 0.6);
    }
  }
}

.Input__Icon {
  height: 45px;
  display: flex;
  align-items: center;
  position: absolute;
  left: 18px;
  top: 0;

  svg {
    fill: $text-light-grey;
    transition: fill .3s ease;
  }
}

.Input__Error {
  display: block;
  position: absolute;
  top: 50px;
  left: 0;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: $red;
  opacity: 0;
  transition: opacity .3s ease;
}

.Input__Icon_pass {
  right: 18px;
  left: auto;
  cursor: pointer;
}

.Input__PassTooltip {
  & > span {
    display: block;
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
    margin-bottom: 12px;
  }

  ul {
    li {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      img {
        margin-right: 6px;
      }

      span {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: $text-dark-grey;
      }
    }
  }
}

.Input__PassStrength {
  display: flex;
  align-content: inherit;
  justify-content: space-between;
  margin: 18px 0;

  ul {
    display: flex;
    align-items: center;

    li {
      width: 28px;
      height: 5px;
      background: $light-grey;
      border-radius: 1px;
      margin-right: 6px;
    }
  }

  span {
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
    color: $green;
  }
}

.Input__PassStrength_bad {
  span {
    color: $red;
  }

  ul {
    li:nth-child(1) {
      background-color: $red;
    }
  }
}

.Input__PassStrength_normal {
  span {
    color: $yellow;
  }

  ul {
    li:nth-child(1),
    li:nth-child(2) {
      background-color: $yellow;
    }
  }
}

.Input__PassStrength_good {
  span {
    color: $green;
  }

  ul {
    li:not(:last-child) {
      background-color: $green;
    }
  }
}

.Input__PassStrength_strong {
  span {
    color: $green;
  }

  ul li {
    background-color: $green;
  }
}

.Input_isError {
  input {
    border-color: $red;
    color:  $red;
    margin-bottom: 22px;
  }

  .Input {
    &__Icon {
      svg {
        fill: $red;
      }
    }

    &__Error {
      opacity: 1;
    }
  }
}

.Input_isHidden {
  display: none;
}

.Input_pro {
  position: relative;
  background-color: $grey;
  border: 1px solid $grey;
  border-radius: 8px;
  padding: 30px 22px 10px;
  transition-property: background-color, border-color, margin-bottom;
  transition-duration: .3s;
  transition-timing-function: ease;

  input {
    width: 100%;
    display: block;
    font-size: 16px;
    line-height: 22px;
    color: $text-dark-blue;
    font-weight: 600;
    background-color: transparent;
    border: none;
    padding: 0;
    transition: box-shadow .3s ease;
    -webkit-box-shadow: 0 0 0 1000px $grey inset !important;
  }

  label{
    display: block;
    position: absolute;
    left: 24px;
    top: 22px;
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
    color: $text-grey;
    cursor: text;
    transition-property: top, left, font-size;
    transition-duration: .3s;
    transition-timing-function: ease;
  }

  .Input {
    &__Error {
      font-size: 12px;
      line-height: 16px;
      color: $red;
      position: absolute;
      left: 0;
      bottom: -14px;
      transform: translateY(100%);
    }

    &__Icon_pass {
      right: 24px;
      top: 50%;
      transform: translateY(-50%);
      background-color: $grey;
      z-index: 1;
      transition: background-color .3s ease;
    }
  }

  &.Input {
    &_isError {
      background-color: $pink-light;
      border-color: $red;
      padding-right: 70px;
      margin-bottom: 50px;

      input {
        margin-bottom: 0;
        color: $text-dark-blue;
        -webkit-box-shadow: 0 0 0 1000px $pink-light inset !important;
      }

      label {
        color: $red
      }

      &::after{
        display: block;
        content: '';
        width: 24px;
        height: 24px;
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        right: 24px;
        background: url('../../../public/close-pink.svg') no-repeat center center / 100%;
      }

      .Input__Icon_pass {
        background-color: $pink-light;
      }
    }

    &_isFocus,
    &_isFilled {
      label {
        font-size: 12px;
        line-height: 16px;
        top: 12px;
      }
    }

    &_isFocus:not(&_isError) {
      background-color: $light-grey;
      border-color: $text-light-grey;

      input {
        -webkit-box-shadow: 0 0 0 1000px $light-grey inset !important;
      }

      .Input__Icon_pass {
        background-color: $light-grey;
      }
    }
  }
}

@media screen and (max-width: $tablet-small) {
  .Input_pro {
    label {
      left: 18px;
    }
  }
}
