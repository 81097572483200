@import "../../assets/styles/variables";

.Button {
  width: 100%;
  height: 44px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  font-weight: 600;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
  text-decoration: none;
  overflow: hidden;
  transition: color .3s ease, background-color .3s ease;

  img {
    margin-right: 10px;
  }
}

.Button__Loader {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  pointer-events: none;
  background-color: inherit;
  transition: opacity .3s ease;

  svg {
    margin: auto;
    display: block;
    animation-name: loaderSpin;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
}

.Button_blue {
  background-color: $blue;

  &:hover {
    background-color: $text-blue;
  }
}

.Button_darkBluePro {
  background-color: $text-dark-blue;

  &:hover {
    background-color: $dark-blue;
  }
}

.Button_white {
  color: $blue;
  background-color: #fff;

  &:hover {
    background-color: $text-blue;
    color: #fff;
  }
}

.Button_header {
  width: 150px;
  background-color: rgba(156, 177, 255, 0.2);
  border-radius: 8px;
  color: $light-grey;

  &:hover {
    background-color: $text-blue;
    color: #fff;
  }
}

.Button_big {
  height: 62px;
  font-size: 16px;
}

.Button_medium {
  height: 54px;
  font-size: 16px;
}

.Button_isLoading {
  pointer-events: none;

  .Button__Loader {
    opacity: 1;
  }
}

@media screen and (max-width: $tablet-small) {
  .Button_big {
    height: 56px;
  }
}


@keyframes loaderSpin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}
